import { Link } from 'gatsby';
import { SectionSubtitle } from '../Typography';
import colors from 'src/style/colors';
import { mediaQueries } from 'src/style/breakpoints';
import styled from 'src/style';

export const Arrow = styled.div(({ isOpen }) => ({
  marginLeft: 30,
  transform: isOpen ? 'rotate(-90deg)' : 'rotate(90deg)',
  transition: 'transform 0.3s ease-in-out',
}));

export const ProfileItem = styled.li({
  cursor: 'pointer',
  paddingBottom: 10,
  transitionProperty: 'all',
  transitionDuration: '0.3s',
  transitionTimingFunction: 'ease-in-out',
  transitionDelay: '0s',
  '&:hover': { color: colors.yellow },
});

export const ProfileListItems = styled.ul(({ isOpen, fromFormationsPageType }) => ({
  visibility: isOpen ? 'visible' : 'hidden',
  opacity: isOpen ? 1 : 0,
  listStyle: 'none',
  position: 'absolute',
  padding: fromFormationsPageType === 'E-Learning' ? '0px 0px 10px 25px' : '0px 0px 20px 25px',
  width: 400,
  '@media only screen and (max-width: 400px)': { width: '100vw' },
  backgroundColor: colors.white,
  borderRadius: 5,
  marginLeft: -25,
  transition: 'visibility 0s, opacity 0.2s ease-in-out',
}));

export const ProfileDropDownMenu = styled.div({
  // listStyle: 'none',
  zIndex: 5,
  paddingLeft: 10,
  [mediaQueries.phoneOnly]: {
    paddingLeft: 0,
  },
});

export const ProfileSelected = styled(SectionSubtitle)({
  color: colors.purple,
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
});

export const FeaturesList = styled.ul(
  {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    listStyle: 'none',
    flexWrap: 'wrap',
    width: '100%',
    [mediaQueries.tabletPortraitUp]: {
      flexDirection: 'row',
    },
  },
  ({ landing }) => ({
    marginBottom: landing ? '10px' : '20px',
    marginTop: landing ? 0 : '70px',
  })
);

export const CardMoreInfosWrapper = styled.div({
  backgroundColor: colors.white,
  position: 'relative',
  borderRadius: 10,
  margin: '10px 25px',
  width: 230,
  height: 230,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 'rgba(39, 44, 49, 0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px',
  transition: 'all 0.5s ease',
  ':hover': {
    boxShadow: 'rgba(39, 44, 49, 0.07) 8px 28px 50px, rgba(39, 44, 49, 0.04) 1px 6px 12px',
    transition: 'all 0.4s ease',
    transform: 'translate3D(0, -1px, 0) scale(1.01)',
  },
  '@media only screen and (min-width: 600px) and (max-width: 900px)': {
    width: '20vw',
    height: '20vw',
    minWidth: 150,
    minHeight: 150,
    margin: '10px 5% 10px 0',
    // ':last-child': { marginRight: 0 },
  },
});

export const CardMoreInfosContainer = styled(Link)({
  textDecoration: 'none',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%',
  height: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  // WebkitMaskImage: '-webkit-radial-gradient(white, black)',
  borderRadius: 10,
  // [mediaQueries.tabletLandscapeUp]: {
  //   height: 430,
  // },
  // [mediaQueries.desktopUp]: {
  //   height: 455,
  // },
});
