import React from 'react';

import { H3, H4, TextContainer, TextContainerNotJustify } from '../Typography/index';
import { mediaQueries } from 'src/style/breakpoints';
import Check from 'src/components/Icons/tick.svg';

const style = {
  padding: 0,
  marginLeft: 20,
  '&>ul>li': {
    listStyle: 'none',
    lineHeight: '28px',
    paddingBottom: 10,
    listStyleImage: `url(${Check})`,
    paddingLeft: 15,
    [mediaQueries.phoneOnly]: {
      lineHeight: '25px',
    },
  },
};

const BulletsPoints = ({ content, h3 = false, h4 = false, customStyles = {} }) => {
  if (Array.isArray(content) === true) {

    let Title = null;
    if (h3) Title = H3;
    if (h4) Title = H4;

    content = content.map((item, i) => (
      <li key={i}>{Title !== null ? <Title>{item}</Title> : item}</li>
    ));

    return (
      <TextContainerNotJustify css={{ ...style, ...customStyles }}>
        <ul>{content}</ul>
      </TextContainerNotJustify>
    );
  } else {
    return (
      <TextContainer
        css={{ ...style, ...customStyles }}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  }
};

export default BulletsPoints;
