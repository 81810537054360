import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
/* --- import Components ----*/
import Layout from 'src/components/Layout';
import { TopbarStaticSpace } from 'src/components/Topbar';
import {
  HeaderDescription,
  HeaderTitle,
  SectionTitle,
  TextContainer,
  UppercaseWords,
} from 'src/components/Typography';
import { HeroLeft, HeroWrapper } from 'src/components/Hero/elements';
import { Section, SectionOverlap, TwoColumns } from 'src/components/Section';
import { BulletsPoints } from 'src/components/CorporateCourses/index';
import { TagContainer } from 'src/components/Tags/elements';
import Tag from 'src/components/Tags/Tag';
import { ProjectsOverview } from 'src/components/Projects';
import QALandingPage from 'src/components/QuestionsAnswer/QALandingPage';
/* --- import Style and Image ----*/
import { mediaQueries } from 'src/style/breakpoints';
import sizes from 'src/style/sizes';
import colors from 'src/style/colors';
import { Program } from 'src/components/Bootcamp/Schedule/Program';
import { QuestionCTA } from 'src/components/Bootcamp';

export default ({ data, location }) => {
  const {
    metaTitle,
    metaDescription,
    duration,
    name,
    summary,
    resume,
    alt,
    goals,
    prerequisites,
    tags,
    targetSkills,
  } = require('src/data/formation-developpeur-full-stack');

  return (
    <Layout
      pathname={location.pathname}
      businessType="B2C"
      pageName={name}
      pageType="course"
      metaTitle={`${metaTitle} - ${duration} | Le Reacteur`}
      metaDescription={metaDescription}
    >
      <TopbarStaticSpace />
      {/* --- TOP SECTION --- */}
      <Section theme="primary" hero={true} margin>
        <HeroWrapper notCentered>
          <HeroLeft css={{ flex: 1 }}>
            <HeaderTitle>
              Formation Concepteur - Développeur d’applications Full Stack
              <br />
            </HeaderTitle>
            <TagContainer wrapped css={{ marginTop: '40px' }}>
              {tags
                ? tags.map((tag) => {
                    return (
                      <Tag key={tag.tagName} color={tag.color}>
                        {tag.tagName}
                      </Tag>
                    );
                  })
                : null}
            </TagContainer>
            <HeaderDescription
              css={{
                marginTop: 32,
                textAlign: 'center',
                [mediaQueries.desktopUp]: {
                  marginRight: 60,
                  textAlign: 'left',
                },
              }}
              dangerouslySetInnerHTML={{ __html: summary }}
            ></HeaderDescription>
          </HeroLeft>
        </HeroWrapper>
      </Section>
      {/* --- PRESENTATION --- */}
      <SectionOverlap marginTop={-100} theme="secondary">
        <div css={{ paddingTop: 64 }}>
          <TwoColumns
            sizeLeft={sizes.columnWidth.large}
            left={
              <div>
                <SectionTitle>Présentation</SectionTitle>
                <TextContainer
                  css={{
                    '&>p': { paddingTop: 16 },
                    '&>p>a': { color: colors.purple, textDecoration: 'none' },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: resume,
                  }}
                />
                <GatsbyImage
                  image={data.imageReactNative.childImageSharp.gatsbyImageData}
                  alt={alt}
                  css={{
                    flex: 1,
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 5,
                    marginTop: 32,
                    boxShadow: '5px 20px 40px rgba(0, 0, 0, 0.2)',
                    [mediaQueries.phoneOnly]: {
                      height: '70%',
                    },
                    [mediaQueries.tabletPortraitUp]: {
                      height: '70%',
                    },
                    [mediaQueries.tabletLandscapeUp]: {
                      height: '85%',
                    },
                    [mediaQueries.desktopUp]: {
                      height: '100%',
                    },
                  }}
                />
              </div>
            }
            sizeRight={sizes.columnWidth.small}
            right={
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: colors.white,
                  padding: 45,
                  marginLeft: 16,
                  borderRadius: 5,
                  [mediaQueries.phoneOnly]: {
                    padding: 0,
                    marginTop: 32,
                    backgroundColor: colors.lightGrey,
                    marginLeft: 0,
                  },
                }}
              >
                <UppercaseWords css={{ fontSize: 14, paddingBottom: 22 }}>Objectifs</UppercaseWords>
                <BulletsPoints content={goals} h3 />
                <div css={{ height: 35 }} />
                <UppercaseWords css={{ fontSize: 14, paddingBottom: 22 }}>
                  Pré-Requis
                </UppercaseWords>
                <BulletsPoints content={prerequisites} />
                <div css={{ height: 35 }} />
              </div>
            }
          />
        </div>
      </SectionOverlap>
      {/* --- PROGRAM --- */}
      <Program course="full-stack" />
      {/* --- TARGET SKILLS  --- */}
      <Section theme="light">
        <SectionTitle css={{ marginBottom: 80, width: '100%' }} isMobileCenter isCenter>
          Compétences
          <span css={{ color: colors.yellow }}>
            <br
              css={{
                display: 'none',
                [mediaQueries.phoneOnly]: {
                  display: 'block',
                },
              }}
            />
            &nbsp;visées
          </span>
        </SectionTitle>
        <BulletsPoints content={targetSkills} h3 />
      </Section>
      {/* --- PROJECTS --- */}=
      <ProjectsOverview bootcampType="full-stack" hideStudentsWork={true} hideProjects={true} />
      {/* --- FAQ --- */}
      <QALandingPage fromPage="full-stack" />
      {/* --- ADDITIONAL QUESTIONS?? --- */}
      <QuestionCTA fromFormationsPage="full-stack" />
      {/* --- PARTNERS --- */}
      {/* <PartnersProSection title="Nous les avons formés" theme="secondary" /> */}
    </Layout>
  );
};

export const query = graphql`
  query {
    imageReactNative: file(relativePath: { eq: "2023_screen3.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }
  }
`;
