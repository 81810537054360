const md = require('markdown-it')();
const dedent = require('dedent-js');

module.exports = {
  //   id: 'node-js',
  metaTitle: 'Formation Concepteur - Développeur d’applications Full Stack',
  metaDescription:
    'Formation avancée en Full Stack pour développeurs, axée sur les technologies Web et Mobile. Maîtrisez le développement front-end et back-end pour créer des applications robustes et performantes.',
  duration: '4 à 18 mois selon le rythme de la formation choisie',
  durationDays: 3,
  name: 'Formation Concepteur - Développeur d’applications Full Stack',
  summary: md.render(
    dedent`Cette formation avancée en Full Stack est destinée aux développeurs souhaitant approfondir leurs compétences et maîtriser les technologies de pointe du développement Web et Mobile. En couvrant des techniques avancées de développement front-end et back-end, elle permet d'acquérir une expertise complète pour créer des applications Web et Mobiles robustes et performantes.`
  ),
  resume: md.render(dedent`
 Boostez vos compétences avec notre formation full stack !  
 Cette formation, donnée par un formateur expert, permet d'analyser et d'identifier les besoins et fonctionnalités d'un projet d'application Web ou Mobile, de planifier et de suivre le développement de l'application dans un contexte Agile, ainsi que de maîtriser la gestion de la qualité du projet. 
 &nbsp;  
 Le programme inclut le développement d'un back-end, avec la mise en place de contrôleurs, la réalisation de tests, et la manipulation de bases de données.
 Pour le front-end, les apprenants utiliseront des outils No-Code ou bien un framework pour créer des interfaces utilisateurs en responsive design, tout en respectant les bonnes pratiques de sécurité.
 &nbsp;  
 Enfin, la formation couvre la mise en production, l'automatisation du déploiement, ainsi que l'optimisation des performances.
`),
  goals: [
    'Découvrir et maîtriser les bases de la programmation JavaScript',
    'Utiliser les outils de développement',
    'Développer des sites Internet “responsive',
    'Créer des serveurs (APIs) et les déployer',
    'Concevoir et administrer des bases de données situées dans le Cloud',
    'Installer, configurer et utiliser des bibliothèques JavaScript',
    'Utiliser la bibliothèque React pour la création de sites Web dynamiques',
    'Construire des applications Mobile pour iOS et Android avec React Native',
  ],
  prerequisites: [
    'Être titulaire d’un diplôme ou titre professionnel de niveau 5, ou,',
    'Avoir une expérience professionnelle dans le numérique d’au moins 1 an',
  ],
  tags: [
    { tagName: 'web+mobile', color: 'purpleDark' },
    { tagName: 'à\u00A0distance', color: 'purpleVeryLight' },
    { tagName: 'entre\u00A04\u00A0et\u00A018\u00A0mois', color: 'yellow' },
  ],
  targetSkills: [
    'Concevoir une application web ou mobile',
    'Piloter un projet de développement d’application web ou mobile',
    'Développer le back-end sécurisée d’une application web ou mobile',
    'Développer le front-end d’une application web ou mobile',
    'Déployer une application web ou mobile',
  ],
};
